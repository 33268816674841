<template>
  <section class="section section--404">
    <div class="container">
      <div class="admin-wrapper">
        <div class="product-block">
          <h1 class="title">New Product</h1>
          <form class="form" @submit.prevent="createProduct">
            <div class="form__sidebar">
              <div class="form__cats-wrap">
                <div class="form__wrap">
                  <div class="form__label">Category</div>
                  <multiselect
                    :value="category"
                    :options="categories"
                    track-by="id"
                    label="name"
                    :searchable="false"
                    :show-labels="false"
                    class="form__select"
                    :disabled="!!newCat"
                    @select="selectCategory"
                  >
                    <span slot="caret" slot-scope="{toggle}" class="arrow" @mousedown.prevent.stop="toggle">
                      <div class="icon"><i class="ri-arrow-drop-down-line"></i></div>
                    </span>
                  </multiselect>

                  <div v-if="!inputCat" class="form__add" @click="inputCat = true">
                    <div class="form__add-new">Add New</div>
                  </div>
                  <div v-if="inputCat">
                    <label class="form__input-label">
                      <input
                        v-model="newCat"
                        type="text"
                        class="form__input form__input--light"
                        placeholder="Add New"
                      />
                      <button type="button" class="icon-close" @click="newCat = null">×</button>
                    </label>
                  </div>
                </div>
                <div class="form__wrap">
                  <div class="form__label">SubCategory</div>
                  <multiselect
                    :value="subCategory"
                    :options="subCats"
                    track-by="id"
                    label="name"
                    :searchable="false"
                    :show-labels="false"
                    class="form__select"
                    :disabled="!!!subCats.length || !!newCat || !!newSubCat"
                    @select="selectSubCategory"
                  >
                    <span slot="caret" slot-scope="{toggle}" class="arrow" @mousedown.prevent.stop="toggle">
                      <div class="icon"><i class="ri-arrow-drop-down-line"></i></div>
                    </span>
                  </multiselect>

                  <div
                    v-if="!inputSubCat"
                    class="form__add"
                    :class="{'form__add--disabled': !(!!category || !!newCat)}"
                    @click="inputSubCat = true"
                  >
                    <div class="form__add-new">Add New</div>
                  </div>
                  <div v-if="inputSubCat">
                    <label class="form__input-label">
                      <input
                        v-model="newSubCat"
                        type="text"
                        class="form__input form__input--light"
                        placeholder="Add New"
                      />
                      <button type="button" class="icon-close" @click="newSubCat = null">×</button>
                    </label>
                  </div>
                </div>

                <div class="form__wrap">
                  <div class="form__label">Sub SubCategory</div>
                  <multiselect
                    :value="subSubCategory"
                    :options="subSubCats"
                    track-by="id"
                    label="name"
                    :searchable="false"
                    :show-labels="false"
                    class="form__select"
                    :disabled="!!!subSubCats.length || !!newCat || !!newSubCat || !!newSubSubCat"
                    @select="selectSubSubCategory"
                  >
                    <span slot="caret" slot-scope="{toggle}" class="arrow" @mousedown.prevent.stop="toggle">
                      <div class="icon"><i class="ri-arrow-drop-down-line"></i></div>
                    </span>
                  </multiselect>

                  <div
                    v-if="!inputSubSubCat"
                    class="form__add"
                    :class="{'form__add--disabled': !(!!subCategory || !!newSubCat)}"
                    @click="inputSubSubCat = true"
                  >
                    <div class="form__add-new">Add New</div>
                  </div>
                  <div v-if="inputSubSubCat">
                    <label class="form__input-label">
                      <input
                        v-model="newSubSubCat"
                        type="text"
                        class="form__input form__input--light"
                        placeholder="Add New"
                      />
                      <button type="button" class="icon-close" @click="newSubSubCat = null">×</button>
                    </label>
                  </div>
                </div>
              </div>
              <div class="form__f">
                <div class="form__label">Filter Options</div>
                <multiselect
                  v-model="filters"
                  :options="filtersList"
                  label="name"
                  track-by="id"
                  :close-on-select="true"
                  :clear-on-select="false"
                  :hide-selected="true"
                  :preserve-search="true"
                  :taggable="true"
                  :multiple="true"
                  placeholder=""
                  class="form__select"
                  :disabled="!(!!subSubCategory || !!newSubSubCat)"
                  @tag="addFilter"
                >
                  <!--         -->
                  <span slot="caret" slot-scope="{toggle}" class="arrow" @mousedown.prevent.stop="toggle">
                    <div class="icon"><i class="ri-arrow-drop-down-line"></i></div>
                  </span>
                  <template slot="tag">{{ '' }}</template>
                </multiselect>
              </div>
              <div class="form__filters">
                <div v-for="(f, idx) in filters" :key="f.name">
                  <div class="form__filter">
                    {{ f.name }}
                    <span class="form__filter-close" @click="removeFilter(idx)"><i class="ri-close-line"></i></span>
                  </div>
                </div>
              </div>
              <div class="form__check">
                <div class="checkbox" @click="isBestSeller = !isBestSeller">
                  <i v-if="isBestSeller" class="ri-check-line"></i>
                </div>
                <p class="form__option">Set 'BestSeller' mark for this product</p>
              </div>
            </div>

            <div class="form__main">
              <div class="form__row">
                <label>
                  <span class="form__label">Product Name</span>
                  <label class="form__input-label">
                    <input v-model="product" type="text" class="form__input form__input--light" />
                  </label>
                </label>

                <label>
                  <span class="form__label">ID</span>
                  <label class="form__input-label">
                    <input
                      v-model="productID"
                      type="text"
                      class="form__input form__input--small form__input--light"
                      :disabled="editPage"
                    />
                  </label>
                </label>
              </div>
            </div>
            <div class="form__sidebar">
              <div class="form__label">Products Accessories</div>
              <div v-for="(acc, id) in accessories" :key="acc.accessoryId" class="form__row-3">
                <label class="form__input-label">
                  <input
                    v-model="acc.accessory"
                    type="text"
                    class="form__input form__input--small form__input--light form__input--light-acc"
                    placeholder="Add Title"
                    disabled
                  />
                </label>
                <label class="form__input-label">
                  <input
                    v-model="acc.accessoryId"
                    type="text"
                    class="form__input form__input--small form__input--light form__input--light-acc"
                    placeholder="Add Title"
                    disabled
                  />
                </label>
                <div>
                  <i class="ri-delete-bin-2-line" @click="removeAcc(id)"></i>
                </div>
              </div>

              <div class="form__row-3">
                <label class="form__input-label">
                  <input
                    v-model="newAcc.accessory"
                    type="text"
                    class="form__input form__input--light form__input--light-acc"
                    placeholder="Add Title"
                  />
                </label>
                <label class="form__input-label">
                  <input
                    v-model="newAcc.accessoryId"
                    type="text"
                    class="form__input form__input--light form__input--light-acc"
                    placeholder="Add ID"
                  />
                </label>
                <div>
                  <i class="ri-add-circle-line icon-yellow" @click="createAcc"></i>
                </div>
              </div>
            </div>

            <div class="form__photos-section">
              <div class="form__photos-wrapper">
                <div v-for="(photo, i) of photos" :key="i + Math.random()" class="form__photos-hover">
                  <div class="form__photos-active center">
                    <i class="ri-delete-bin-2-line icon-yellow"></i>
                  </div>
                  <img :src="photo" class="form__photos-img" />
                </div>
                <div
                  v-for="(photo, i) of productPhotos"
                  :key="i"
                  class="form__photos-hover"
                  @click="selectPhoto(photo)"
                >
                  <div class="form__photos-active center">
                    <i class="ri-delete-bin-2-line icon-yellow"></i>
                  </div>
                  <img :src="photo.url" class="form__photos-img" />
                </div>

                <label class="form__label-wrap center">
                  <div class="form__img-add-wrap center">
                    <i class="ri-image-add-fill"></i>
                  </div>
                  <div class="form__img-title">Add Photo</div>
                  <input type="file" multiple class="form__input" @change="addImage" />
                </label>
              </div>
            </div>

            <div class="btn-wrap">
              <button type="submit" class="btn btn--bgr">Save Product</button>
              <router-link to="/admin/products/" class="btn">Discard</router-link>
            </div>
          </form>

          <!-- <div v-if="productInfo && productInfo.photos">
            <img v-for="(img, i) of productInfo.photos" :key="i" :src="img.url" @click="selectPhoto(img)" />
            <div class="btn-wrap"><button class="btn btn--bgr" @click="removePhotos">Delete photos</button></div>
          </div> -->
        </div>
      </div>
    </div>
    <div v-if="isLoading" class="loader">
      <loader
        object="#ffffff"
        color1="#ffffff"
        color2="#ffffff"
        size="5"
        speed="1"
        bg="#636669"
        objectbg="#FFE248"
        opacity="100"
        disableScrolling="false"
        name="spinning"
      ></loader>
    </div>
  </section>
</template>

<script>
import {required, requiredIf} from 'vuelidate/lib/validators'
import {mapGetters, mapActions, mapState} from 'vuex'
import Multiselect from 'vue-multiselect'
import _ from 'lodash'

export default {
  name: 'AdminProductCreate',
  components: {
    Multiselect
  },
  data() {
    return {
      isLoading: false,

      product: null,
      productID: null,

      category: null,
      newCat: null,
      inputCat: false,

      subCategory: null,
      subCats: [],
      newSubCat: null,
      inputSubCat: false,

      subSubCategory: null,
      subSubCats: [],
      newSubSubCat: null,
      inputSubSubCat: false,

      filters: [],
      filtersList: [],
      newFilters: [],

      files: [],
      photos: [],
      selectedPhotos: [],
      productPhotos: [],
      editPage: false,

      addAcc: false,
      accessories: [],
      productAccs: [],
      newAcc: {
        accessory: null,
        accessoryId: null
      },

      isBestSeller: false
    }
  },
  computed: {
    ...mapGetters({
      categories: 'main/categories'
    }),
    ...mapState({
      productInfo: s => s.products.product
    })
  },
  async created() {
    window.scrollTo(0, 0)
    const id = this.$route.params.id
    if (id) {
      this.editPage = true
      await this.getProduct(id)
      this.mergeProduct()
    }
  },
  methods: {
    ...mapActions({
      getProduct: 'products/fetchProduct',
      create: 'products/create',
      changeCategory: 'main/changeCategory',
      fetchConstants: 'main/fetchConstants',
      addPhoto: 'products/addPhoto',
      delPhotos: 'products/delPhotos'
    }),
    //================================================================
    selectCategory(cat) {
      this.category = cat
      this.subCategory = this.newSubCat = this.newSubSubCat = null
      this.inputCat = this.inputSubCat = this.inputSubSubCat = false
      this.filtersList = this.filters = []
      this.subCats = cat.items || []
    },
    selectSubCategory(cat) {
      this.subCategory = cat
      this.subSubCategory = this.newSubSubCat = null
      this.inputSubCat = this.inputSubSubCat = false
      this.filtersList = this.filters = []
      this.subSubCats = cat.items || []
    },
    selectSubSubCategory(cat) {
      this.subSubCategory = cat
      this.filters = []
      this.filtersList = cat.items || []
    },
    addFilter(filter) {
      const newFilter = {
        id: this.filtersList.length,
        name: filter
      }
      this.newFilters.push(newFilter)
      this.filtersList.push(newFilter)
      this.filters.push(newFilter)
    },
    removeFilter(id) {
      this.filters.splice(id, 1)
    },
    //================================================================
    async createProduct() {
      if (this.$v.$invalid) {
        this.$v.$touch()
        return
      }
      if (this.newCat) {
        this.categories.push({
          id: this.categories.length,
          name: this.newCat,
          items: []
        })
      }
      const fCat = this.categories.find(c => c.name === this.newCat || this.category)?.items
      if (this.newSubCat) {
        fCat.push({
          id: fCat.length,
          name: this.newSubCat,
          items: []
        })
      }
      if (this.newSubSubCat) {
        const fSubCat = fCat.find(c => c.name === this.newSubCat || this.subCategory).items
        fSubCat.push({
          id: fSubCat.length,
          name: this.newSubSubCat,
          items: []
        })

        if (this.filters.length) {
          fSubCat.find(c => c.name === this.newSubSubCat).items = this.filters
        }
      }
      const formData = {
        category: this.newCat || this.category.name,
        // subCategory: this.newSubCat || this.subCategory.name,
        product: this.product,
        productId: '' + this.productID,
        accessories: this.accessories
      }
      if (this.productPhotos.length) {
        formData.photos = this.productPhotos
      }

      // НЕ робе
      if (this.newSubCat || this.subCategory) {
        formData.subCategory = this.newSubCat || this.subCategory.name
      }
      //
      if (this.newSubSubCat || this.subSubCategory) {
        formData.subSubCategory = this.newSubSubCat || this.subSubCategory.name
      }
      //checked
      if (this.isBestSeller) {
        formData.isBestSeller = true
        console.log(formData)
      } else {
        formData.isBestSeller = false
      }
      this.changeCategory(this.categories)
      if (this.filters.length) formData.filters = this.filters.map(f => f.name)
      if (this.accessories.length) formData.accessories = this.accessories
      try {
        this.isLoading = true
        await this.create(formData)
        await this.removePhotos()
        if (this.files.length) {
          await this.addPhoto({filesList: this.files, id: '' + this.productID, urlsList: this.productInfo.photos})
        }
      } finally {
        this.isLoading = false
      }
      this.$router.push('/admin/products/')
    },
    addImage(e) {
      this.files = e.target.files
      this.files.forEach(f => this.photos.push(window.URL.createObjectURL(f)))
    },
    async selectPhoto(img) {
      this.productPhotos.splice(this.productPhotos.indexOf(img), 1)
      this.selectedPhotos.push(img)
    },
    async removePhotos() {
      if (!this.selectedPhotos.length) return
      const newPhotosList = _.differenceWith(this.productInfo.photos, this.selectedPhotos, _.isEqual)
      this.productInfo.photos = newPhotosList
      await this.delPhotos({files: this.selectedPhotos, id: this.productID, filesList: newPhotosList})
    },
    mergeProduct() {
      const {product, productId, category, subCategory, subSubCategory, filters, photos, accessories, isBestSeller} =
        this.productInfo
      this.product = product
      this.productID = productId

      if (category) {
        this.category = this.categories.find(c => c.name === category)
        this.subCats = this.category.items
      }
      if (subCategory) {
        this.subCategory = this.subCats.find(c => c.name === subCategory)
        this.subSubCats = this.subCategory.items
      }
      if (subSubCategory) {
        this.subSubCategory = this.subSubCats.find(c => c.name === subSubCategory)
        this.filtersList = this.subSubCategory.items
      }
      if (filters) {
        this.filters = this.filtersList.filter(f => filters.indexOf(f.name) > -1)
      }
      if (photos) {
        this.productPhotos = photos
      }
      if (accessories) {
        this.accessories = accessories
      }
      if (isBestSeller) this.isBestSeller = isBestSeller
    },
    reset() {
      this.category =
        this.catInfo =
        this.subCategory =
        this.subCatInfo =
        this.subSubCategory =
        this.product =
        this.productID =
        this.filter =
        this.newCat =
        this.newSubCat =
        this.newSubSubCat =
          null
      this.filters = this.filtersList = this.newFilters = this.subCats = this.subSubCats = []
      this.inputCat = this.inputSubCat = this.inputSubSubCat = false
    },
    removeAcc(id) {
      this.accessories.splice(id, 1)
    },
    createAcc() {
      const {accessory, accessoryId} = this.newAcc
      if (!(accessory || accessoryId)) return
      this.accessories.push(this.newAcc)

      const resetAcc = {
        accessory: null,
        accessoryId: null
      }
      this.newAcc = resetAcc
    }
  },
  validations: {
    category: {
      required: requiredIf(function () {
        if (!this.newCat) return true
      })
    },
    subCategory: {
      required: requiredIf(function () {
        if (!this.newSubCat) return true
      })
    },
    product: {required},
    productID: {required}
  }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style lang="scss" scoped>
@import 'style';
</style>
